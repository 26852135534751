.size48x48 {
  height: 48px;
  width: 48px;
}

.size64x64 {
  height: 64px;
  width: 64px;
  object-fit: contain;
  object-position: 50% 35%;
}

.size96x96 {
  height: 96px;
  width: 96px;
}

.size128x128 {
  height: 128px;
  width: 128px;
}
