@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';

@mixin placeholderStyles {
  color: rgba(#000, 0.3);
}

%placeholderStyles {
  &::placeholder {
    @include placeholderStyles();
  }
}

form.vanilla {
  .form-group {
    // margin-bottom: 0;

    &.row {
      // margin-bottom: 0;
      // min-height: 4.5em;
    }
  }

  .form-row {
    min-height: 3rem;
  }

  label.required {
    font-weight: bold;
    color: grey;
  }

  span.input-group-text {
    border-radius: 0;
    border-left: 0;
    border-top: 0;
  }

  select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    height: auto !important;
    background-color: transparent;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 10px) calc(1em + 2px), calc(100% - 5px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;

    &:not(.chosen) {
      @include placeholderStyles();

      &:required {
        font-weight: bold;
      }
    }
  }

  input:not(.geosuggest__input),
  select,
  .form-control {
    border: 0;
    border-bottom: solid 1px lightgrey;
    border-radius: 0px;

    &.is-invalid {
      border-color: $form-feedback-invalid-color !important;
    }

    &.is-valid {
      border-color: $form-feedback-valid-color !important;
    }

    &.placeholder {
      @include placeholderStyles();
    }

    &:hover {
      border-bottom: solid 1px darkgrey;
    }

    &:focus {
      outline: 0;
    }

    &:required::placeholder {
      font-weight: bold;
    }

    &::placeholder {
      @include placeholderStyles();
    }
  }
}

$bsColor: rgba(#000, 0.08);
$reqColor: red;

// Using a placeholder to avoid polluting the name-space
%labelStyles {
  box-shadow: 0 1px rgba(#000, 0.08);

  &.required {
    position: relative;
    color: inherit;
  }
}

// This is selecting using Bootstrap's label class
// & any labels under contained within an element
// with .standard-form--label-borders or any element
// with a .standard-form__label--border class

.standard-form {
  &--absolute-form-feedback {
    .standard-form__input-feedback {
      position: absolute;
      bottom: -18px;
    }
  }

  &--member-edit {
    &.disabled-form .form-group {
      input,
      select {
        pointer-events: none;
      }
    }
  }

  &--required-indicator:not(.disabled-form):not(.was-validated) {
    .form-group .col-form-label.required + .col {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 15px;
        height: 1px;
        width: 90px;
        background: $reqColor;
        background: linear-gradient(to right, $reqColor, transparent 30%, transparent);
        opacity: 0.1;
        animation: required 2s ease-in-out infinite;
      }
    }
  }

  &--label-borders {
    label,
    .col-form-label {
      @extend %labelStyles;
    }
  }

  &--light-placeholders.vanilla,
  &--light-placeholders {
    input,
    .form-control {
      @extend %placeholderStyles;
    }

    select.form-control:not(.chosen),
    .form-control.placeholder:not(.chosen) {
      @include placeholderStyles();
    }
  }

  &__label {
    &--border {
      @extend %labelStyles;
    }
  }

  &__input {
    &--light-placeholders {
      @extend %placeholderStyles;
    }
  }
}

@keyframes required {
  0%,
  100% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }
}
