$shadowSize: 0.2rem;
$shadowColor: rgb(198, 223, 255);
$boxShadow: 0 0 0 $shadowSize $shadowColor;

.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 100%;
  margin: 0 auto 1em;
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  border: 0;
  box-shadow: 0 0 0 0 $shadowColor;
  font-size: 16px; // fallback
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: box-shadow 0.2s ease;
}
.geosuggest__input:focus {
  box-shadow: 0 0 0 $shadowSize $shadowColor;
  outline: 0;
  transition: box-shadow 0.2s ease;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  background: #fff;
  box-shadow: $boxShadow;
  margin-top: $shadowSize; 
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  opacity: 1;
  transition: max-height 0.2s ease,
    opacity 0.15s ease;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.2s ease,
    opacity 0.15s ease;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;

  &:not(:last-child) {
    box-shadow: 0 1px rgba(#000, .1);
  }
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}
