@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/_mixins.scss';
@import './image_sizes.scss';

// add an xxl breakpoint
$grid-breakpoints: map-merge(
  $grid-breakpoints,
  (
    xxl: 1500px,
  )
);
$container-max-widths: map-merge(
  $container-max-widths,
  (
    xxl: 1440px,
  )
);

// Hide number input arrows in Webkit
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Hide number input arrows in Firefox
input[type='number'] {
  -moz-appearance: textfield;
}

.input-color {
  color: $input-color;
}

// Get rid of Bootstrap margin below tables
.table {
  margin-bottom: 0;
}

// Show form feedback for Typeahead components
.dropdown-item:not(:disabled):not(.disabled),
.nav-link:not(:disabled):not(.disabled),
.was-validated .rbt.is-invalid ~ .invalid-feedback {
  display: block;
}

// Show form feedback for Typeahead components
.was-validated .rbt.is-invalid ~ .invalid-feedback {
  display: block;
}

// Hide DateRangePicker input fields
.daterangepicker .calendar .daterangepicker_input {
  display: none;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em !important;
  opacity: 0.5;
  background: none;
  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 0.5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #fcfcfa;
  }
}

.active {
  font-weight: bold;
}


.btn-facebook {
  color: #fff;
  background-color: #4267b2;
  border-color: #4267b2;

  &:hover {
    color: #fff;
    background-color: #294170;
    border-color: #294170;
  }

  .icon-facebook {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    width: 0.875em;
    overflow: visible;
    vertical-align: -0.125em;
  }
}


.btn-social-auth {
  flex: 1 1 275px;
  text-align: center;

  padding: 15px;
  margin: 10px;
  border: solid 1px #666;
  border-radius: 6px;

  background-repeat: no-repeat;
  background-position: 15px;
  background-size: 20px 20px;
  background-color: white;
  color: #333;

  &:hover {
    text-decoration: none;
    color: #444;
    background-color: rgba(20, 61, 86, 0.04);
    // box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
  }

  transition: background-color .3s
}

.btn-apple-auth {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDU2LjY5MyA1Ni42OTMiIGhlaWdodD0iNTYuNjkzcHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1Ni42OTMgNTYuNjkzIiB3aWR0aD0iNTYuNjkzcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnPjxwYXRoIGQ9Ik00MS43NzcsMzAuNTE3Yy0wLjA2Mi02LjIzMiw1LjA4Mi05LjIyMSw1LjMxMi05LjM3MmMtMi44OTEtNC4yMjctNy4zOTUtNC44MDctOC45OTgtNC44NzMgICBjLTMuODMtMC4zODktNy40NzcsMi4yNTYtOS40MiwyLjI1NmMtMS45MzksMC00Ljk0MS0yLjE5OS04LjExNy0yLjE0M2MtNC4xNzgsMC4wNjItOC4wMjksMi40My0xMC4xNzksNi4xNyAgIGMtNC4zMzksNy41MjctMS4xMSwxOC42ODIsMy4xMTgsMjQuNzkxYzIuMDY3LDIuOTg2LDQuNTMyLDYuMzQ2LDcuNzY2LDYuMjIzYzMuMTE3LTAuMTIzLDQuMjkzLTIuMDE2LDguMDYxLTIuMDE2ICAgczQuODI2LDIuMDE2LDguMTIzLDEuOTUzYzMuMzUyLTAuMDYxLDUuNDc3LTMuMDQzLDcuNTI3LTYuMDQxYzIuMzczLTMuNDY5LDMuMzUtNi44MjgsMy40MDgtNi45OTggICBDNDguMzA1LDQwLjQzMyw0MS44NDQsMzcuOTU4LDQxLjc3NywzMC41MTd6Ii8+PHBhdGggZD0iTTM1LjU4MiwxMi4yMjljMS43MTUtMi4wODIsMi44NzctNC45NzUsMi41NjEtNy44NTVjLTIuNDc1LDAuMS01LjQ3MSwxLjY0NS03LjI0OCwzLjcyNSAgIGMtMS41OTIsMS44NDYtMi45ODQsNC43ODUtMi42MTEsNy42MTNDMzEuMDQ1LDE1LjkyNiwzMy44NjEsMTQuMzA3LDM1LjU4MiwxMi4yMjl6Ii8+PC9nPjwvc3ZnPg==)
}

.btn-google-auth {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
}

.btn-microsoft-auth {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMyAyMyI+PHBhdGggZmlsbD0iI2YzZjNmMyIgZD0iTTAgMGgyM3YyM0gweiIvPjxwYXRoIGZpbGw9IiNmMzUzMjUiIGQ9Ik0xIDFoMTB2MTBIMXoiLz48cGF0aCBmaWxsPSIjODFiYzA2IiBkPSJNMTIgMWgxMHYxMEgxMnoiLz48cGF0aCBmaWxsPSIjMDVhNmYwIiBkPSJNMSAxMmgxMHYxMEgxeiIvPjxwYXRoIGZpbGw9IiNmZmJhMDgiIGQ9Ik0xMiAxMmgxMHYxMEgxMnoiLz48L3N2Zz4=);
}

.btn-facebook-auth {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEyOCAxMjgiIGlkPSJTb2NpYWxfSWNvbnMiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDEyOCAxMjgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIGlkPSJfeDMxX19zdHJva2UiPjxnIGlkPSJGYWNlYm9va18xXyI+PHJlY3QgZmlsbD0ibm9uZSIgaGVpZ2h0PSIxMjgiIHdpZHRoPSIxMjgiLz48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02OC4zNjksMTI4SDcuMDY1QzMuMTYyLDEyOCwwLDEyNC44MzYsMCwxMjAuOTM1ICAgIFY3LjA2NUMwLDMuMTYyLDMuMTYyLDAsNy4wNjUsMGgxMTMuODcxQzEyNC44MzcsMCwxMjgsMy4xNjIsMTI4LDcuMDY1djExMy44N2MwLDMuOTAyLTMuMTYzLDcuMDY1LTcuMDY0LDcuMDY1SDg4LjMxOFY3OC40MzEgICAgaDE2LjYzOGwyLjQ5MS0xOS4zMThIODguMzE4VjQ2Ljc4YzAtNS41OTMsMS41NTMtOS40MDQsOS41NzMtOS40MDRsMTAuMjI5LTAuMDA0VjIwLjA5NGMtMS43NjktMC4yMzUtNy44NDEtMC43NjEtMTQuOTA2LTAuNzYxICAgIGMtMTQuNzQ5LDAtMjQuODQ2LDkuMDAzLTI0Ljg0NiwyNS41MzV2MTQuMjQ2SDUxLjY4OHYxOS4zMThoMTYuNjgxVjEyOHoiIGZpbGw9IiM0NDYwQTAiIGZpbGwtcnVsZT0iZXZlbm9kZCIgaWQ9IkZhY2Vib29rIi8+PC9nPjwvZz48L3N2Zz4=)
}

.card-footer .brand {
  color: rgba(0, 0, 0, 0.26);
  font-size: 14px;
  text-shadow: white 0px 1px 1px;
}

.overflow-auto {
  overflow: auto;
}

.w-auto {
  width: auto !important;
}

.min-h-100vh {
  min-height: 100vh !important;
}

dd.hover-glow:hover {
  border: 4px yellow;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.hover-glow:hover {
  border: 4px yellow;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.strong {
  font-weight: bold;
}

.nobr {
  white-space: nowrap;
}

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .nobr-#{$name} {
      white-space: nowrap;
    }

    .border-top-#{$name} {
      border-top: $border-width solid $border-color !important;
    }
    .border-right-#{$name} {
      border-right: $border-width solid $border-color !important;
    }
    .border-bottom-#{$name} {
      border-bottom: $border-width solid $border-color !important;
    }
    .border-left-#{$name} {
      border-left: $border-width solid $border-color !important;
    }

    .border-top-#{$name}-0 {
      border-top: 0 !important;
    }
    .border-right-#{$name}-0 {
      border-right: 0 !important;
    }
    .border-bottom-#{$name}-0 {
      border-bottom: 0 !important;
    }
    .border-left-#{$name}-0 {
      border-left: 0 !important;
    }

    .border-#{$name}-x {
      border-left: $border-width solid $border-color !important;
      border-right: $border-width solid $border-color !important;
    }

    .border-#{$name}-y {
      border-top: $border-width solid $border-color !important;
      border-bottom: $border-width solid $border-color !important;
    }
  }
}

/* Stripe Elements
-------------------------------------------------- */
@media (max-width: 320px) {
  .stripe-card-element {
    padding-left: 0;
    padding-right: 0;
  }
}

.StripeElement:not(.PaymentRequestButton) {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: $input-border-width solid $input-border-color;
  @include box-shadow($input-box-shadow);
  @include transition($input-transition);
}

.StripeElement--focus {
  color: $input-focus-color;
  background-color: $input-focus-bg;
  border-color: $input-focus-border-color;
  outline: 0;
  // Avoid using mixin so we can pass custom focus shadow properly
  @if $enable-shadows {
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
  } @else {
    box-shadow: $input-focus-box-shadow;
  }

  &.StripeElement--invalid {
    box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
  }

  &.StripeElement--complete {
    box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-valid-color, 0.25);
  }
}

.StripeElement--invalid {
  border-color: $form-feedback-invalid-color;
}

.StripeElement--complete {
  border-color: $form-feedback-valid-color;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/* Avatar
-------------------------------------------------- */
.UserAvatar--inner {
  display: inline-block;
  text-decoration: none !important;
  text-transform: uppercase;
  overflow: hidden;
}

.UserAvatar--light {
  color: $dark;
}

.UserAvatar--dark {
  color: $light;
}

a.menu-link.nav-link {
  color: #bbb;
  font-weight: bold;
}

a.menu-link.nav-link:hover {
  color: white;
  background: #007bff1c;
}
