// @import '~bootstrap/scss/bootstrap.scss';

@import '../../shared/styles/site-globals.scss';

// Hacky fix because 100vh doesn't work right on mobile
// http://webandapp.fr/blog/2017/09/fullscreen-height-issue-reopened/
@include media-breakpoint-down(sm) {
  html,
  body,
  #root,
  #root > .container,
  #root > .container > .row,
  #root > .container > .row > .card {
    // height: 100%;

    // fixes issue where card content overflows 100% and content
    // after root is rendered on top of the root container.
    min-height: 100vh;
    height: auto;
  }
}

// Hide Card borders on smaller devices where it's full-width anyway
@include media-breakpoint-down(xs) {
  #root > .container > .row > .card {
    border: none;
    border-radius: 0;
  }
}

.nav-buttons {
  padding: $card-spacer-y $card-spacer-x;
}

.card-subhead {
  font-weight: bold;
  text-decoration: uppercase;
  background: #ccc;
}

table.invoice {
  tr {
    line-height: 0.9rem;
  }

  tr.donation-row {
    line-height: 1.1rem;
  }

  td {
    padding: 0.75rem 1.45rem;
  }
}

.container {
  width: auto;
  max-width: 680px;
  padding: 0 15px;
}

.remove-card-body-margin {
  margin: 0 (-$card-spacer-x);
}
